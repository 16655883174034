<template>
    <loading-card :loading="!loaded">
        <div class="card-header">{{ $t('crud.pages._show') }}
            <span class="pull-right" v-if="record.url"><a :href="outgoingUrl" target="_blank" class="btn btn-sm btn-outline-primary"><i class="fa fa-link"></i> Bekijk pagina</a></span></div>
        <div class="card-body">
            <pages-form-component @submit="updateFromRoute" :record="record" :loading="loading" />
        </div>
    </loading-card>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import PagesFormComponent from "./Form";

    export default {
        name: 'pages-show-component',
        mixins: [functions],
        components: {PagesFormComponent},
        data() {
            return {
                crud: config('pages')
            };
        },
        computed: {
            outgoingUrl() {
                return this.$config.VUE_APP_WEB_URL + this.original_record.url;
            }
        }
    }
</script>
